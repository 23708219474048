import { Button } from '../../../../components';
import { ChevronDownIcon, DocumentIcon, LinkIcon, TrashIcon, PlusIcon } from '@heroicons/react/outline';
import toast from 'react-hot-toast';
import { Disclosure } from '@headlessui/react';
import { useClassNames } from '../../../../hooks/useClassNames';
import { FormikCheckbox } from '../../../../components/Form/Formik/FormikCheckbox';
import FormikField from '../../../../components/Form/Formik/FormikField';
import FormikErrorMessage from '../../../../components/Form/Formik/FormikErrorMessage';
import FormikArrayControl from '../../../../components/Form/Formik/FormikArrayControl';
import FormikInput from '../../../../components/Form/Formik/FormikInput';
import { Select } from '../../../../components/Form/Select';
import { PlaceholderInput } from '@metaforcelabs/metaforce-core';
import { useTranslation } from 'react-i18next';
import { resources, translations } from '../../../../translations/translationsConstants';
import { signingSequenceType } from '../const';
import { memo, useEffect, useState } from 'react';
import ListBox from '../../../../components/Listbox';

const PLACEHOLDERS = [
  {
    id: '1',
    name: 'Document name',
    placeholder: '[DOCUMENTNAME]',
    icon: DocumentIcon,
    required: false
  },
  {
    id: '2',
    name: 'Sign url',
    placeholder: '[SIGN-URL]',
    icon: LinkIcon,
    required: true
  }
];

const placeholderRegex = /\[(.+?)\]/g;

const StoringTypes = {
  None: 0,
  Optional: 1,
  Mandatory: 2
};

const PersonToSign = ({
  formikProps,
  languages,
  archiveSettings,
  appSettings,
  onLanguageChange
}) => {
  const [storeFolderOptions, setStoreFolderOptions] = useState(null);

  const classNames = useClassNames();
  const { t } = useTranslation([resources.orderCreate, resources.common]);
  const [availableSignAuthMethods, setAvailableSignAuthMethods] = useState([]);

  const getAvailableSignAuthMethods = (providerMethodsList) => {
    const optionsForSigning = [
        {value: 'standard', name: 'Sign via E-mail'}, 
        {value: 'no_bankid', name: 'Sign via BankId (NO)'}, 
        {value: 'se_bankid', name: 'Sign via BankId (SE)'}, 
    ];
    let result = [];
    Object.keys(providerMethodsList).forEach((methodKey) => {
        if(providerMethodsList[methodKey]) 
        {
          const method = optionsForSigning.find(option => option.value === methodKey);
          if(method) result.push(method);
        }
    })
    setAvailableSignAuthMethods(result);
};

  const handleOnLanguageChange = (languageCode) => {
    formikProps.setFieldTouched('language', true);
    onLanguageChange(languageCode, formikProps.values);
  };

  const handleOnArchiveFolderChange = (value) => {
    formikProps.setFieldValue('archiveFolderId', value);
  };

  const handleOnOrderChange = (value, name, values, setField) => {
    if (value === signingSequenceType.DependsOnInitial) {
      const isInitialOrder = values.signatories.find(
        (s) => s.signingSequence === signingSequenceType.Initial
      );
      if (isInitialOrder) {
        setField(name, value);
      } else {
        toast(t(translations.orderCreate.personToSignStep.validation.initialOrderType), {
          duration: 400,
          type: 'error'
        });
      }
    } else {
      setField(name, value);
    }
  };
  
  const checkIfLastFieldIsEmpty = (arrayHelpers) => {
    if(arrayHelpers[arrayHelpers.length - 1].emailAddress.length > 0) return false;
    return true;
  }

  useEffect(() => {
    if(appSettings?.availableSignAuthorizationMethods) 
      getAvailableSignAuthMethods(appSettings.availableSignAuthorizationMethods);
    else setAvailableSignAuthMethods([]);
  }, [appSettings]);

  useEffect(() => {
    let folders = archiveSettings.availableFolders.map((a) => ({
      name: a.name,
      additionalText: a.fullPath,
      value: a.id
    }));

    if (archiveSettings.archiveType === StoringTypes.Optional) {
      folders = [
        {
          name: 'None',
          additionalText: 'Do not store in Archive',
          value: null
        },
        ...folders
      ];

      formikProps.setFieldValue('archiveFolderId', null);
    }

    setStoreFolderOptions(folders);
  }, []);

  return (
    <>
      <div data-cy="personToSignStep" className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div>
          <h1 className="mb-2 text-xl leading-6 font-medium  text-gray-900">
            {t(translations.orderCreate.personToSignStep.title)}
          </h1>
          <p className="mb-2 max-w-4xl text-sm text-gray-500">
            {t(translations.orderCreate.personToSignStep.text)}
          </p>
        </div>

        <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5 divide-y divide-gray-200">
          <div className="pt-5 space-y-2">
            {appSettings?.letUserDefineSignatoriesSigningOrder && (
              <div>
                <FormikCheckbox
                  label={t(translations.orderCreate.personToSignStep.defineSignatureOrder)}
                  name="useSignatureOrder"
                  formikProps={formikProps}
                  styles="focus:ring-gray-400 focus:border-gray-400 h-4 w-4 text-indigo-600 rounded"
                />
              </div>
            )}
            {appSettings?.enableSendingEmailAfterSigningIsCompleted && (
              <div>
                <FormikCheckbox
                  label={t(translations.orderCreate.personToSignStep.toAllSignatories)}
                  name="distributeSignedDocumentToAllSignatories"
                  formikProps={formikProps}
                  styles="focus:ring-gray-400 focus:border-gray-400 h-4 w-4 text-indigo-600 rounded"
                />
              </div>
            )}

            <div>
              <FormikField name="signatories">
                {({ field, form, meta }) => (
                  <>
                    <FormikArrayControl name="signatories">
                      {(arrayHelpers) => (
                        <ul data-cy="createOrderSignatoriesList" className="list-inside">
                          {field.value.map((v, index) => (
                            <li
                              key={index}
                              className="py-5 text-sm first:border-0 border-t border-gray-200 text-gray-700"
                            >
                              <div className="grid gap-4 grid-cols-1 md:grid-cols-4 lg:grid-cols-3">
                                <div
                                  className={classNames.classNames(
                                    'col-span-1',
                                    appSettings?.displayLastName &&
                                      appSettings?.displayFirstName &&
                                      'md:col-span-2 lg:col-span-2'
                                  )}
                                >
                                  <div
                                    className={classNames.classNames(
                                      'grid gap-4 grid-cols-1',
                                      appSettings?.displayLastName &&
                                        appSettings?.displayFirstName &&
                                        'md:grid-cols-2 lg:grid-cols-4'
                                    )}
                                  >
                                  {appSettings?.availableSignAuthorizationMethods 
                                    && (availableSignAuthMethods?.length > 0) && (
                                    <Select
                                      name={`${field.name}[${index}].signatureAuthorizationChoosenMethod`}
                                      selectedValue={v.signatureAuthorizationChoosenMethod}
                                      onChange={(value) => {
                                        handleOnOrderChange(
                                          value,
                                          `${field.name}[${index}].signatureAuthorizationChoosenMethod`,
                                          form.values,
                                          form.setFieldValue
                                        );
                                      }}
                                      onInit={() => {
                                        handleOnOrderChange(
                                          availableSignAuthMethods[0].value,
                                          `${field.name}[${index}].signatureAuthorizationChoosenMethod`,
                                          form.values,
                                          form.setFieldValue
                                        );
                                      }}
                                      options={availableSignAuthMethods}
                                    />
                                  )}
                                    <FormikInput
                                      name={`signatories.${index}.emailAddress`}
                                      placeholder="E-mail"
                                      formikProps={form}
                                      required={true}
                                      isAutoCompleteDisabled={true}
                                      onChange={(event) => {
                                        arrayHelpers.replace(index, {
                                          ...v,
                                          emailAddress: event.target.value
                                        });
                                      }}
                                    />
                                    {appSettings?.displayFirstName && (
                                      <FormikInput
                                        name={`signatories.${index}.firstName`}
                                        placeholder="First name"
                                        required={true}
                                        isAutoCompleteDisabled={true}
                                        formikProps={form}
                                        onChange={(event) => {
                                          arrayHelpers.replace(index, {
                                            ...v,
                                            firstName: event.target.value
                                          });
                                        }}
                                      />
                                    )}

                                    {appSettings?.displayLastName && (
                                      <FormikInput
                                        name={`signatories.${index}.lastName`}
                                        placeholder="Last name"
                                        required={true}
                                        isAutoCompleteDisabled={true}
                                        formikProps={form}
                                        onChange={(event) => {
                                          arrayHelpers.replace(index, {
                                            ...v,
                                            lastName: event.target.value
                                          });
                                        }}
                                      />
                                    )}
                                    {appSettings?.displayPersonalNumber && (
                                      <FormikInput
                                        name={`signatories.${index}.personalNumber`}
                                        placeholder="Personal number"
                                        required={true}
                                        isAutoCompleteDisabled={true}
                                        formikProps={form}
                                        onChange={(event) => {
                                          arrayHelpers.replace(index, {
                                            ...v,
                                            personalNumber: event.target.value
                                          });
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>

                                <div className="flex justify-end md:justify-start">
                                  <Button
                                    variant={Button.variants.icon}
                                    className={classNames.classNames(
                                      index === 0 && 'invisible'
                                    )}
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <TrashIcon className="ml-auto w-5 h-5 cursor-pointer transition-colors hover:text-brandPrimary" />
                                  </Button>

                                  {/*<div className="ml-4">*/}
                                  {/*  <Button*/}
                                  {/*    variant={Button.variants.icon}*/}
                                  {/*    classNames={classNames(*/}
                                  {/*      (v.emailAddress.length === 0 || !form.values.useSignatureOrder || index === 0) &&*/}
                                  {/*        'invisible'*/}
                                  {/*    )}*/}
                                  {/*    disabled={index === 0}*/}
                                  {/*    onClick={() => {*/}
                                  {/*      arrayHelpers.swap(index, index - 1);*/}
                                  {/*    }}*/}
                                  {/*  >*/}
                                  {/*    <ChevronUpIcon*/}
                                  {/*      className={classNames(*/}
                                  {/*        'ml-auto w-5 h-5 cursor-pointer transition-colors hover:text-brandPrimary',*/}
                                  {/*        index === 0 && 'cursor-not-allowed'*/}
                                  {/*      )}*/}
                                  {/*    />*/}
                                  {/*  </Button>*/}
                                  {/*</div>*/}

                                  {/*<div>*/}
                                  {/*  <Button*/}
                                  {/*    variant={Button.variants.icon}*/}
                                  {/*    classNames={classNames(*/}
                                  {/*      (v.emailAddress.length === 0 ||*/}
                                  {/*        !form.values.useSignatureOrder ||*/}
                                  {/*        index === field.value.length - 2) &&*/}
                                  {/*        'invisible'*/}
                                  {/*    )}*/}
                                  {/*    disabled={index === field.value.length - 2}*/}
                                  {/*    onClick={() => {*/}
                                  {/*      arrayHelpers.swap(index, index + 1);*/}
                                  {/*    }}*/}
                                  {/*  >*/}
                                  {/*    <ChevronDownIcon*/}
                                  {/*      className={classNames(*/}
                                  {/*        'ml-auto w-5 h-5 cursor-pointer transition-colors hover:text-brandPrimary',*/}
                                  {/*        index === field.value.length - 1 && 'cursor-not-allowed'*/}
                                  {/*      )}*/}
                                  {/*    />*/}
                                  {/*  </Button>*/}
                                  {/*</div>*/}

                                  <Select
                                    name={`${field.name}[${index}].signingSequence`}
                                    className={classNames.classNames(
                                      (v.emailAddress.length === 0 ||
                                        !form.values.useSignatureOrder) &&
                                        'invisible'
                                    )}
                                    selectedValue={v.signingSequence}
                                    onChange={(value) => {
                                      handleOnOrderChange(
                                        value,
                                        `${field.name}[${index}].signingSequence`,
                                        form.values,
                                        form.setFieldValue
                                      );
                                    }}
                                    options={[
                                      {
                                        name: t(
                                          translations.orderCreate.personToSignStep
                                            .initialOrderValue
                                        ),
                                        value: signingSequenceType.Initial
                                      },
                                      {
                                        name: t(
                                          translations.orderCreate.personToSignStep
                                            .dependsOnOrderValue
                                        ),
                                        value: signingSequenceType.DependsOnInitial
                                      },
                                      {
                                        name: t(
                                          translations.orderCreate.personToSignStep.noOrderValue
                                        ),
                                        value: signingSequenceType.NoOrder
                                      }
                                    ]}
                                  />
                                </div>
                              </div>
                            </li>
                          ))}
                          <Button
                                variant={Button.variants.secondary}
                                disabled={checkIfLastFieldIsEmpty(field.value)}
                                onClick={() => {arrayHelpers.push(
                                 {                         
                                    emailAddress: '',
                                    firstName: '',
                                    lastName: '',
                                    signingSequence: signingSequenceType.NoOrder,
                                    signedData: '',
                                    signUrl: '',
                                    signStatus: 0
                                  }
                                )}}
                              >
                                <PlusIcon className="mr-3 w-5 h-5 cursor-pointer transition-colors hover:text-brandPrimary" /> Add another recipient
                          </Button>
                        </ul>
                      )}
                    </FormikArrayControl>

                    {typeof meta.error === 'string' && <FormikErrorMessage name="signatories" />}
                  </>
                )}
              </FormikField>
            </div>


          </div>

          <div className="pt-5">
            <div className="divide-y divide-gray-200 space-y-6 sm:space-y-5">
              {archiveSettings.archiveType !== StoringTypes.None && storeFolderOptions && (
                <div className="">
                  <p className="block text-sm font-medium text-gray-700">
                    {t(translations.orderCreate.personToSignStep.archiveFolder)}
                  </p>

                  <div className="mt-1 w-56">
                    <ListBox
                      value={formikProps.values.archiveFolderId}
                      options={storeFolderOptions}
                      onChange={handleOnArchiveFolderChange}
                    />
                  </div>
                </div>
              )}

              <div className="pt-5">
                <div className="w-44">
                  <Select
                    name="language"
                    label={t(translations.orderCreate.personToSignStep.signingLanguage)}
                    selectedValue={formikProps.values.language}
                    onChange={handleOnLanguageChange}
                    options={languages.map((l) => ({ name: l.name, value: l.languageCode }))}
                  />
                </div>
              </div>

              {(appSettings?.displaySigningMessageSubject ||
                appSettings?.displaySigningMessageBody) && (
                <Disclosure as="div" className="pt-5">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="text-left w-full flex justify-between items-center text-gray-400">
                        <span className="text-sm font-medium text-gray-900">
                          {t(translations.orderCreate.personToSignStep.digitalSigningMessage)}
                        </span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames.classNames(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-6 w-6 transform'
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        {appSettings?.displaySigningMessageSubject && (
                          <div>
                            <FormikField name="signingNotificationTitle" className="mt-4">
                              {({ field, form }) => (
                                <>
                                  {' '}
                                  <PlaceholderInput
                                    name={field.name}
                                    inputValue={field.value}
                                    placeholderRgx={placeholderRegex}
                                    className={
                                      'shadow-sm block w-full sm:text-sm border border-gray-300 rounded-md focus-within:ring-gray-400 focus-within:ring-1 focus-within:border-gray-400'
                                    }
                                    placeholderText={t(
                                      translations.orderCreate.personToSignStep
                                        .digitalSigningMessageTitle
                                    )}
                                    placeholders={PLACEHOLDERS}
                                    onChange={({ name, value }) => {
                                      form.setFieldValue(field.name, value);
                                    }}
                                  />
                                  <FormikErrorMessage name={field.name} />
                                </>
                              )}
                            </FormikField>
                          </div>
                        )}

                        {appSettings?.displaySigningMessageBody && (
                          <FormikField name="signingNotificationMessage" className="mt-4">
                            {({ field, form }) => (
                              <>
                                <PlaceholderInput
                                  name={field.name}
                                  inputValue={field.value}
                                  placeholderRgx={placeholderRegex}
                                  className={
                                    'shadow-sm block w-full sm:text-sm border border-gray-300 rounded-md focus-within:ring-gray-400 focus-within:ring-1 focus-within:border-gray-400'
                                  }
                                  placeholderText={t(
                                    'personToSignStep.digitalSigningMessageMessage'
                                  )}
                                  placeholders={PLACEHOLDERS}
                                  onChange={({ name, value }) => {
                                    form.setFieldValue(field.name, value);
                                  }}
                                />
                                <FormikErrorMessage name={field.name} />
                              </>
                            )}
                          </FormikField>
                        )}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              )}

              {(appSettings?.displayEmailSubject || appSettings?.displayEmailBody) && (
                <Disclosure as="div" className="pt-5">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="text-left w-full flex justify-between items-center text-gray-400">
                        <span className="text-sm font-medium text-gray-900">
                          {t(translations.orderCreate.personToSignStep.digitalSigningEmail)}
                        </span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames.classNames(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-6 w-6 transform'
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        {appSettings?.displayEmailSubject && (
                          <div>
                            <FormikField name="emailNotificationSubject" className="mt-4">
                              {({ field, form }) => (
                                <>
                                  <PlaceholderInput
                                    name={field.name}
                                    inputValue={field.value}
                                    placeholderRgx={placeholderRegex}
                                    className={
                                      'shadow-sm block w-full sm:text-sm border border-gray-300 rounded-md focus-within:ring-gray-400 focus-within:ring-1 focus-within:border-gray-400'
                                    }
                                    placeholderText={t(
                                      translations.orderCreate.personToSignStep
                                        .digitalSigningEmailTitle
                                    )}
                                    placeholders={PLACEHOLDERS}
                                    onChange={({ name, value }) => {
                                      form.setFieldValue(field.name, value);
                                    }}
                                  />
                                  <FormikErrorMessage name={field.name} />
                                </>
                              )}
                            </FormikField>
                          </div>
                        )}

                        {appSettings?.displayEmailBody && (
                          <FormikField name="emailNotificationMessage" className="mt-4">
                            {({ field, form }) => (
                              <>
                                <PlaceholderInput
                                  name={field.name}
                                  inputValue={field.value}
                                  placeholderRgx={placeholderRegex}
                                  className={
                                    'shadow-sm block w-full sm:text-sm border border-gray-300 rounded-md focus-within:ring-gray-400 focus-within:ring-1 focus-within:border-gray-400'
                                  }
                                  placeholderText={t(
                                    translations.orderCreate.personToSignStep
                                      .digitalSigningEmailMessage
                                  )}
                                  placeholders={PLACEHOLDERS}
                                  onChange={({ name, value }) => {
                                    form.setFieldValue(field.name, value);
                                  }}
                                />
                                <FormikErrorMessage name={field.name} />
                              </>
                            )}
                          </FormikField>
                        )}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(PersonToSign);

import React, { useCallback, useEffect, useState } from 'react';
import { Button, Badge, MenuContextList } from '../../../components';
import {
  getAllSignatureFiles,
  getOrderDetails,
  getCertifiedPdf,
  getSigningOrderList,
  getSignatureFile,
  deleteOrder,
  sendReminders,
  getPortalUISettingsApi,
  getFileFromProviderApi,
  cancelOrder
} from '../../../api/signingOrder';
import { ChevronUpIcon, ChevronDownIcon, DocumentDownloadIcon } from '@heroicons/react/outline';
import { stringToLocaleDateTimeString } from '../../../utils/date';
import DateTimeParser from '../../../utils/DateTimeParser';
import { convertToHumanFileSize } from '../../../utils/file';
import { useToastAction } from '../../../hooks/useToastAction';
import Filter from './components/Filter';
import { ORDER_STATUS, PROVIDERS, SIGN_STATUS } from './consts';
import { useTranslation } from 'react-i18next';
import { resources, translations } from '../../../translations/translationsConstants';
import Table2 from '../../../components/Table2';

export default function DigitalSigning() {
  const [orderDetails, setOrderDetails] = useState(null);
  const [orderList, setOrderList] = useState([]);
  const [portalUISettings, setPortalUISettings] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState('');
  const [signatoriesId, setSignatoriesId] = useState('');
  const [filters, setFilters] = useState({
    fromDate: DateTimeParser.toLocalJSONFormat(DateTimeParser.subtractYears(1)),
    toDate: DateTimeParser.toLocalJSONFormat(DateTimeParser.setEOD(new Date())),
    isShowMyFieldOnly: false,
    fileStatus: [],
    signature: '', 
    sortBy: 'UpdatedDate',
    sortDesc: true
  });

  const { t } = useTranslation([resources.orderList, resources.common]);
  const orderDetailsAction = useToastAction();
  const downloadSigningProveAction = useToastAction();
  const downloadAllSigningProvesAction = useToastAction();
  const downloadSignedFilesAction = useToastAction();
  const downloadFromProviderAction = useToastAction();
  const signingOrderListAction = useToastAction();
  const cancelOrderAction = useToastAction();
  const remindOrderSignatories = useToastAction();
  const deleteOrderAction = useToastAction();
  const getProvidersSettingsAction = useToastAction();

  const getProvidersSettings = () =>
    getProvidersSettingsAction.execute(async () => {
      const result = await getPortalUISettingsApi();
      setPortalUISettings(result);
    }, 'Failed to get app settings.');

  const getOrderList = (filterValues, setSubmitting) =>
    signingOrderListAction.execute(async () => {
      let signingOrderListResult = await getSigningOrderList(filterValues);

      signingOrderListResult = signingOrderListResult
        .filter((order) => filterList(order, filterValues))
        .map((row) => {
          if (row.packageSize) {
            row.packageSize = convertToHumanFileSize(row.packageSize);
          }

          if (row.updatedDate) {
            row.updatedDate = stringToLocaleDateTimeString(row.updatedDate);
          }

          if (row.providerType) {
            row.providerType = PROVIDERS[row.providerType] || '';
          }

          return row;
        });

      setOrderList(signingOrderListResult);

      if (setSubmitting) {
        setSubmitting(false);
      }
    });

  const handleOrderCancel = useCallback(
    (id) => {
      cancelOrderAction.execute(async () => {
        const cancelOrderResult = await cancelOrder(id);

        if (cancelOrderResult) {
          getOrderList(filters);
        }
      }, t(translations.common.failedToExecuteCancelAction, { ns: resources.common })
      );
    },
    [selectedRowId]
  );

  const handleOnDelete = (id) => {
    deleteOrderAction.execute(async () => {
      await deleteOrder(id);
      getOrderList(filters);
    },
    'Error',
    'Order deleted');
  };

  const handleDownloadSignedFiles = (id) => {
    downloadSignedFilesAction.execute(async () => {
      await getCertifiedPdf(id);
    }, 'Failed to get certified pdf');
  };

  const handleDownloadAllSigningProves = (id) => {
    downloadAllSigningProvesAction.execute(
      async () => await getAllSignatureFiles(id),
      'Failed to get signature files'
    );
  };

  const handleDownloadSigningProve = useCallback((id) => {
    setSignatoriesId(id);
  }, []);

  const handleDownloadFromProvider = (id) => {
    downloadFromProviderAction.execute(
      async () => await getFileFromProviderApi(id),
      'Failed to get file from provider'
    );
  };

  const handleOnRowClick = (row) => {
    setSelectedRowId((id) => (row.id === id ? '' : row.id));
  };

  const handleOnRemind = (id) => {
    remindOrderSignatories.execute(async () => {
      const remindOrderResult = await sendReminders(id);
    });
  };
    
  const handleNewSortingOrder = (columnName) => {
    let filtersWithNewSort;
    let newSortByColumn = columnName.charAt(0).toUpperCase() + columnName.slice(1);
    if(filters.sortBy === newSortByColumn) 
    {
      filtersWithNewSort = {
        ...filters,
        sortBy: newSortByColumn,
        sortDesc: !filters.sortDesc
      }
    }
    else 
    {
      filtersWithNewSort = {
        ...filters,
        sortBy: newSortByColumn,
        sortDesc: false
      }
    }
    setFilters(filtersWithNewSort);
    getOrderList(filtersWithNewSort);
  };

  const handleOnFilter = (values, setSubmitting) => {
    setSelectedRowId('');
    setFilters(values);
    getOrderList(values, setSubmitting);
  };

  const filterList = (order, filters) => {
    if (filters.signature) {
      const foundInCreatedBy = order.createdBy.includes(filters.signature);

      const foundInSignature = order.signatories.some((s) =>
        s.emailAddress.includes(filters.signature)
      );

      if (!foundInSignature && !foundInCreatedBy) {
        return false;
      }
    }

    if (filters.fileStatus.length) {
      const foundFileStatus = filters.fileStatus.includes(order.orderStatus);

      if (!foundFileStatus) {
        return false;
      }
    }

    return true;
  };

  const renderDownloadButton = (signId, signStatus, providerType) => {
    return (
      signStatus === SIGN_STATUS.completed &&
      providerType &&
      portalUISettings.providersSettings[providerType].showDownloadSignaturesButtons && (
        <Button
          variant={Button.variants.icon}
          className="ml-4 pt-0 pr-0 pb-0 pl-0"
          onClick={() => handleDownloadSigningProve(signId)}
        >
          <DocumentDownloadIcon className="w-5 h-5 text-gray-400 hover:text-gray-600" />
        </Button>
      )
    );
  };

  const renderSignatoryStatus = (signStatus, rowProviderType) => {
    return (
      rowProviderType &&
      portalUISettings.providersSettings[rowProviderType].showSignatorySigningProcessStatus && (
        <span className="ml-6">{getSignatoryStatus(signStatus)}</span>
      )
    );
  };

  const getSignatoryStatus = (signStatus) => {
    switch (signStatus) {
      case 1:
        return (
          <Badge
            type="info"
            text={t(translations.common.status.signatory.inProgress, {
              ns: resources.common
            })}
          />
        );
      case 2:
        return (
          <Badge
            type="info"
            text={t(translations.common.status.signatory.started, {
              ns: resources.common
            })}
          />
        );
      case 3:
        return (
          <Badge
            type="success"
            text={t(translations.common.status.signatory.completed, {
              ns: resources.common
            })}
          />
        );
      case 4:
        return (
          <Badge
            type="error"
            text={t(translations.common.status.signatory.canceled, {
              ns: resources.common
            })}
          />
        );
      case 5:
        return (
          <Badge
            type="warn"
            text={t(translations.common.status.signatory.expired, {
              ns: resources.common
            })}
          />
        );
      case 6:
        return (
          <Badge
            type="info"
            text={t(translations.common.status.signatory.forwarded, {
              ns: resources.common
            })}
          />
        );
      case 7:
        return (
          <Badge
            type="error"
            text={t(translations.common.status.signatory.canceled, {
              ns: resources.common
            })}
          />
        );
      case 0:
      default:
        return (
          <Badge
            type="default"
            text={t(translations.common.status.signatory.notSet, {
              ns: resources.common
            })}
          />
        );
    }
  };

  const getOrderStatus = (orderStatus) => {
    switch (orderStatus) {
      case ORDER_STATUS.created:
        return (
          <Badge
            type="info"
            text={t(translations.common.status.order.inProgress, {
              ns: resources.common
            })}
          />
        ); //Created
      case ORDER_STATUS.inProgress:
        return (
          <Badge
            type="info"
            text={t(translations.common.status.order.inProgress, {
              ns: resources.common
            })}
          />
        ); //In Progress
      case ORDER_STATUS.completed:
        return (
          <Badge
            type="success"
            text={t(translations.common.status.order.completed, { ns: resources.common })}
          />
        ); //Completed
      case ORDER_STATUS.download:
        return (
          <Badge
            type="success"
            text={t(translations.common.status.order.completed, {
              ns: resources.common
            })}
          />
        ); //Downloaded From Signing Provider
      case ORDER_STATUS.rejected:
        return (
          <Badge
            type="error"
            text={t(translations.common.status.order.canceled, { ns: resources.common })}
          />
        );
      case ORDER_STATUS.canceled:
        return (
          <Badge
            type="error"
            text={t(translations.common.status.order.canceled, { ns: resources.common })}
          />
        );
      case ORDER_STATUS.error:
        return (
          <Badge
            type="error"
            text={t(translations.common.status.order.error, { ns: resources.common })}
          />
        );
      default:
        return (
          <Badge
            type="default"
            text={t(translations.common.status.order.unknownStatus, {
              ns: resources.common
            })}
          />
        );
    }
  };

  const renderRowActionsBtn = (row) => {
    const actions = [
      {
        id: 1,
        name: t(translations.common.cancel, {
          ns: resources.common
        }),
        condition: ![ORDER_STATUS.canceled, ORDER_STATUS.download, ORDER_STATUS.completed].includes(
          row.orderStatus
        ),
        onClick: () => handleOrderCancel(row.id)
      },
      {
        id: 2,
        name: t(translations.orderList.signedPDF),
        condition: row.orderStatus === ORDER_STATUS.download || row.containsFileToDownload,
        onClick: () => handleDownloadSignedFiles(row.id)
      },
      {
        id: 3,
        name: t(translations.orderList.allSignedXML),
        condition: row.orderStatus === ORDER_STATUS.download || row.containsFileToDownload,
        onClick: () => handleDownloadAllSigningProves(row.id)
      },
      {
        id: 4,
        name: t(translations.orderList.downloadFromProvider),
        condition:
          portalUISettings?.showDownloadSignedFileFromProviderWhenStatusIsCompleted &&
          row.orderStatus === ORDER_STATUS.completed,
        onClick: () => handleDownloadFromProvider(row.id)
      },
      {
        id: 5,
        name: t(translations.common.remind, {
          ns: resources.common
        }),
        condition: [ORDER_STATUS.inProgress].includes(
          row.orderStatus
        ),
        onClick: () => handleOnRemind(row.id)
      },
      {
        id: 6,
        name: t(translations.common.delete, {
          ns: resources.common
        }),
        condition: [ORDER_STATUS.canceled, ORDER_STATUS.download, ORDER_STATUS.completed].includes(
          row.orderStatus
        ),
        onClick: () => handleOnDelete(row.id)
      },
    ].filter((a) => a.condition);

    return actions.length > 0 && <MenuContextList container="body" actions={actions} />;
  };

  useEffect(() => {
    if (!!signatoriesId) {
      downloadSigningProveAction.execute(
        async () => getSignatureFile(selectedRowId, signatoriesId),
        t(translations.orderList.failedToGetSignatureFile)
      );
    }
  }, [signatoriesId]);

  useEffect(() => {
    if (!!selectedRowId) {
      orderDetailsAction.execute(async () => {
        const orderDetailsResult = await getOrderDetails(selectedRowId);

        setOrderDetails(orderDetailsResult);
      }, t(translations.common.failedToLoadData, { ns: resources.common }));
    }
  }, [selectedRowId]);

  useEffect(() => {
    getProvidersSettings();
    getOrderList(filters);
  }, []);

  return (
    <div>
      <div className="flex flex-col pb-5 mb-10 border-b border-gray-200">
        <h1 className="text-3xl font-bold">{t(translations.orderList.title)}</h1>
        <p className="mt-2 max-w-4xl text-sm text-gray-500">{t(translations.orderList.text)}</p>
      </div>

      <Filter orderList={orderList} onSubmit={handleOnFilter} />

      <div className="relative">
        <Table2
          columns={[
            {
              key: 'packageName',
              value: t(translations.orderList.table.name)
            },
            {
              key: 'numOfFiles',
              value: t(translations.orderList.table.numberOfFiles)
            },
            {
              key: 'packageSize',
              value: t(translations.orderList.table.size),
              nowrap: true
            },
            {
              key: 'createdBy',
              value: t(translations.orderList.table.createdBy),
              nowrap: true
            },
            {
              key: 'numOfSignatures',
              value: t(translations.orderList.table.numberOfSignatures)
            },
            {
              key: 'updatedDate',
              value: t(translations.orderList.table.date),
              nowrap: true
            },
            {
              key: 'providerType',
              value: t(translations.orderList.table.provider)
            },
            {
              key: 'orderStatus',
              value: t(translations.orderList.table.status),
              nowrap: true,
              formatter: (row) => getOrderStatus(row.orderStatus)
            },
            {
              key: 'actions',
              value: '',
              className:
                'py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider z-20 sticky right-0 bg-gradient-to-r from-transparent to-gray-50',
              nowrap: true,
              formatter: (row) => renderRowActionsBtn(row),
              formatterClassName:
                'px-6 py-4 text-sm text-gray-500 z-20 sticky right-0 bg-gradient-to-r from-transparent via-white to-white'
            }
          ]}
          rows={orderList}
          expandRow={{
            onClick: handleOnRowClick,
            condition: (row) => orderDetails && orderDetails.id === row.id && selectedRowId,
            expandColumnRenderer: (row) =>
              selectedRowId && selectedRowId === row.id ? (
                <ChevronUpIcon className="w-5 h-5" />
              ) : (
                <ChevronDownIcon className="w-5 h-5" />
              ),
            renderer: (row) =>
              orderDetails.signatories
                .sort((a, b) => (a.signingSequence > b.signingSequence ? 1 : -1))
                .map((s) => (
                  <div key={s.id} className="flex items-center mb-2 md:pl-24">
                    <span className="text-sm">{s.emailAddress}</span>

                    {renderSignatoryStatus(s.signStatus, row.providerType)}

                    {renderDownloadButton(s.id, s.signStatus, row.providerType)}
                  </div>
                ))
          }}
          handleSortBy ={handleNewSortingOrder}
        />
      </div>
    </div>
  );
}
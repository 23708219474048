export const resources = {
  common: 'common',
  home: 'home',
  orderCreate: 'orderCreate',
  orderList: 'orderList'
};

export const translations = {
  common: {
    failedToLoadData: 'failedToLoadData',
    failedToExecuteCancelAction: 'failedToExecuteCancelAction',
    search: 'search',
    clear: 'clear',
    cancel: 'cancel',
    remind: 'remind',
    delete: 'delete',
    next: 'next',
    previous: 'previous',
    send: 'send',
    status: {
      order: {
        created: 'status.order.created',
        inProgress: 'status.order.inProgress',
        completed: 'status.order.completed',
        rejected: 'status.order.rejected',
        canceled: 'status.order.canceled',
        error: 'status.order.error',
        notCompleted: 'status.order.notCompleted',
        unknownStatus: 'status.order.unknownStatus'
      },
      signatory: {
        waiting: 'status.signatory.waiting',
        started: 'status.signatory.started',
        inProgress: 'status.signatory.inProgress',
        completed: 'status.signatory.completed',
        rejected: 'status.signatory.rejected',
        canceled: 'status.signatory.canceled',
        expired: 'status.signatory.expired',
        forwarded: 'status.signatory.forwarded',
        notSet: 'status.signatory.notSet',
        warning: 'status.signatory.warning'
      }
    },
    validation: {
      required: 'validation.required'
    }
  },
  home: {
    title: 'title',
    text: 'text',
    tiles: {
      create: {
        title: 'tiles.create.title',
        text: 'tiles.create.text'
      },
      list: {
        title: 'tiles.list.title',
        text: 'tiles.list.text'
      }
    },
    activities: {
      title: 'activities.title',
      text: 'activities.text',
      failedToGetActivities: 'activities.failedToGetActivities',
      document: 'activities.document',
      documents: 'activities.documents',
      updatedOn: 'activities.updatedOn',
      hasSigned: 'activities.hasSigned'
    }
  },
  orderCreate: {
    title: 'title',
    text: 'text',
    failedToCreateNewOrder: 'failedToCreateNewOrder',
    steps: {
      document: 'steps.document',
      personToSign: 'steps.personToSign',
      summary: 'steps.summary'
    },
    documentStep: {
      title: 'documentStep.title',
      text: 'documentStep.text',
      clickToBrowse: 'documentStep.clickToBrowse',
      orDragAndDrop: 'documentStep.orDragAndDrop',
      filesSizesLimitInfo: 'documentStep.filesSizesLimitInfo',
      fileWithWrongFormat: 'documentStep.fileWithWrongFormat',
      fileWithTheSameNameExist: 'documentStep.fileWithTheSameNameExist',
      list: 'documentStep.list',
      listOfDocuments: 'documentStep.listOfDocuments',
      packageName: 'documentStep.packageName',
      validation: {
        maxLengthCharacters: 'documentStep.validation.maxLengthCharacters',
        packageName: 'documentStep.validation.packageName',
        atLeastDocument: 'documentStep.validation.atLeastDocument',
        fileSize: 'documentStep.validation.fileSize',
        unsupportedFileType: 'documentStep.validation.unsupportedFileType'
      }
    },
    personToSignStep: {
      title: 'personToSignStep.title',
      text: 'personToSignStep.text',
      defineSignatureOrder: 'personToSignStep.defineSignatureOrder',
      initialOrderValue: 'personToSignStep.initialOrderValue',
      dependsOnOrderValue: 'personToSignStep.dependsOnOrderValue',
      noOrderValue: 'personToSignStep.noOrderValue',
      toAllSignatories: 'personToSignStep.toAllSignatories',
      signingLanguage: 'personToSignStep.signingLanguage',
      archiveFolder: 'personToSignStep.archiveFolder',
      digitalSigningMessage: 'personToSignStep.digitalSigningMessage',
      digitalSigningMessageTitle: 'personToSignStep.digitalSigningMessageTitle',
      digitalSigningMessageMessage: 'personToSignStep.digitalSigningMessageMessage',
      digitalSigningEmail: 'personToSignStep.digitalSigningEmail',
      digitalSigningEmailTitle: 'personToSignStep.digitalSigningEmailTitle',
      digitalSigningEmailMessage: 'personToSignStep.digitalSigningEmailMessage',
      validation: {
        initialOrderType: 'personToSignStep.validation.initialOrderType',
        invalidEmail: 'personToSignStep.validation.invalidEmail',
        enterEmail: 'personToSignStep.validation.enterEmail',
        noInitialOrderType: 'personToSignStep.validation.noInitialOrderType',
        atLeastSignatory: 'personToSignStep.validation.atLeastSignatory'
      }
    },
    summary: {
      title: 'summary.title',
      warning: 'summary.warning',
      text: 'summary.text',
      createNewOne: 'summary.createNewOne',
      goToList: 'summary.goToList',
      licence_order_will_exceed_usage: 'summary.licenceOrderWillExceedUsage',
      license_usage_limit_reached: 'summary.licenseUsageLimitReached',
      license_invalid: 'summary.licenseInvalid',
      something_went_wrong_internal: 'summary.somethingWentWrongInternal',
      something_went_wrong_with_external_provider: 'summary.somethingWentWrongWithExternalProvider',
      invalid_personal_number: "summary.invalidPersonalNumber"
    }
  },
  orderList: {
    title: 'title',
    text: 'text',
    failedToGetSignatureFile: 'failedToGetSignatureFile',
    signedPDF: 'signedPDF',
    allSignedXML: 'allSignedXML',
    downloadFromProvider: 'downloadFromProvider',
    filter: {
      signature: 'filter.signature',
      dateFrom: 'filter.dateFrom',
      dateTo: 'filter.dateTo',
      inProcess: 'filter.inProcess',
      signed: 'filter.signed',
      error: 'filter.error',
      canceled: 'filter.canceled',
      myFilesOnly: 'filter.myFilesOnly',
      validation: {
        email: 'filter.validation.email'
      }
    },
    table: {
      name: 'table.name',
      numberOfFiles: 'table.numberOfFiles',
      size: 'table.size',
      createdBy: 'table.createdBy',
      numberOfSignatures: 'table.numberOfSignatures',
      date: 'table.date',
      provider: 'table.provider',
      status: 'table.status'
    }
  }
};

import React, { useContext } from 'react';
import { config } from '../../utils/config';
import { ContentWrapper, MenuBar } from '@metaforcelabs/metaforce-core';
import { OidcRoutesContext } from '../../contexts';

const MissingLicense = () => {
  const oidcContext = useContext(OidcRoutesContext);
  return (
    <div className="fixed flex flex-col bg-gray-50 h-screen w-screen overflow-auto">
      <MenuBar
        subMenuNav={'releasePackages'}
        navigation={[]}
        customerName={oidcContext?.customerInfo?.name}
        customerOrgNumber={oidcContext?.customerInfo?.orgNumber}
        userProfileImage={oidcContext?.userProfile?.userProfileImage}
        homeButtonLink={config.centerpointUiBaseUrl}
      />
      <ContentWrapper>
        <p className="text-2xl mt-6 ml-6">Missing license to access DigitalSigning - Admin</p>
      </ContentWrapper>
    </div>
  );
};

export default MissingLicense;



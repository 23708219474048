import { RedocStandalone } from 'redoc';

const DocumentationAPI = () => {
  return (
    <>
      <RedocStandalone
        specUrl={process.env.REACT_APP_API_BASE_URL + '/swagger/v1/swagger.json'}
        options={{
          nativeScrollbars: true,
          theme: { colors: { primary: { main: '#dd5522' } } }
        }}
      />
    </>
  );
};

export default DocumentationAPI;

import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import './styles.scss';
import { config } from '../../utils/config';
import { setAuthHeader } from '../../api/api';
import { getCustomerDetails } from '../../api/customer';
import { ContentWrapper, MenuBar } from '@metaforcelabs/metaforce-core';

const Oidc = () => {
  const history = useHistory();
  const { oidcUser } = useReactOidc();

  useEffect(() => {
    if (oidcUser) {
      setAuthHeader(oidcUser.access_token);
      validateLicense();
    }
  }, [oidcUser]);

  const navigationMenu = [
    {
      linkElement: <Link to="/">Home</Link>,
      name: "Home",
      current: true,
      featureEnabled: true
    }
  ];

  const validateLicense = async () => {
    const customerDetails = await getCustomerDetails();
    const hasLicense = customerDetails?.activeProducts?.find((a) => a.id === config.productLicense);

    if (hasLicense) {
      const url = new URL(window.location.href);
      const hasParamsURL = url.pathname.substring(1);

      history.push(`/${hasParamsURL}`);
    } else {
      history.push('/missing-license');
    }
  };

  return (
    <div className="fixed flex flex-col bg-gray-50 h-screen w-screen overflow-auto">
      <MenuBar
        subMenuNav={'releasePackages'}
        navigation={navigationMenu}
        homeButtonLink={config.centerpointUiBaseUrl}
      />
      <ContentWrapper>
        <p className="text-2xl font-bold mt-6">Loading...</p>
      </ContentWrapper>
  </div>
  );
};

export default Oidc;

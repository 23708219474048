import { DropField } from '../../../../components';
import FormikField from '../../../../components/Form/Formik/FormikField';
import FormikErrorMessage from '../../../../components/Form/Formik/FormikErrorMessage';
import FormikInput from '../../../../components/Form/Formik/FormikInput';
import { useTranslation } from 'react-i18next';
import { resources, translations } from '../../../../translations/translationsConstants';

const Documents = () => {
  const { t } = useTranslation([resources.orderCreate]);

  return (
    <div data-cy="documentsStep">
      <div className="border-b pb-4">
        <h1 className="mb-2 text-xl leading-6 font-medium text-gray-900">
          {t(translations.orderCreate.documentStep.title)}
        </h1>
        <p className="mb-2 max-w-4xl text-sm text-gray-500">
          {t(translations.orderCreate.documentStep.text)}
        </p>
      </div>

      <div className="space-y-2 md:space-y-5">
        <div className="pt-5">
          <FormikField name="signingOrderFiles">
            {({ field, form }) => {
              return (
                <>
                  <DropField
                    name={field.name}
                    value={field.value}
                    onChange={(value) => {
                      form.setFieldTouched(field.name, true);
                      form.setFieldValue(field.name, value);
                      if (value.length === 1) {
                        form.setFieldValue('packageName', value[0].name);
                      }
                    }}
                    listLabel={t(translations.orderCreate.documentStep.listOfDocuments)}
                  />

                  <FormikErrorMessage name={field.name} />
                </>
              );
            }}
          </FormikField>
        </div>

        <div className="pt-5">
          <div className="mt-4">
            <FormikInput
              label={t(translations.orderCreate.documentStep.packageName)}
              name="packageName"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documents;

import { destroy, download, get, post } from './api';

export const getSigningOrderList = async (data) => {
  return await post(`DigitalSigningOrders/GetListOfOrders`, data);
};

export const getOrderDetails = async (orderId) => {
  return await get(`DigitalSigningOrders/${orderId}`);
};

export const getCreateOrderInitialDataApi = async (languageCode) => {
  return await get(`DigitalSigningOrders/GetCreateOrderInitialData/${languageCode}`);
};

export const getPortalUISettingsApi = async () => {
  return await get(`DigitalSigningOrders/GetPortalUISettings`);
};

export const createNewOrder = async (data) => {
  return await post(`DigitalSigningOrders`, data);
};

export const getCertifiedPdf = async (orderId) => {
  return await download(`DigitalSigningOrders/GetCertifiedPdf/${orderId}`, orderId);
};

export const getAllSignatureFiles = async (orderId) => {
  return await download(`DigitalSigningOrders/GetAllSignatureFiles/${orderId}`, orderId);
};

export const getFileFromProviderApi = async (orderId) => {
  return await download(`DigitalSigningOrders/GetPdfFromProvider/${orderId}`, orderId);
};

export const getSignatureFile = async (selectedRowId, signatoryId) => {
  return await download(
    `DigitalSigningOrders/GetSignatureFile/${selectedRowId}/${signatoryId}`,
    signatoryId
  );
};

export const getActivities = async () => {
  return await get(`Activities`);
};

export const sendReminders = async (orderId) => {
  return await get(`DigitalSigningOrders/SendReminders/${orderId}`);
};

export const logLoginApi = async () => {
  return await post(`Activities/LogLogin`, null);
};

export const cancelOrder = async (orderId) => {
  return await destroy(`DigitalSigningOrders/${orderId}`);
};

export const deleteOrder = async (orderId) => {
  return await destroy(`DigitalSigningOrders/DeleteOrder/${orderId}`);
};
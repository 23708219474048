import DateTimeParser from '../../../../utils/DateTimeParser';
import { Button } from '../../../../components';
import { Formik, Form } from 'formik';
import { FormikCheckbox } from '../../../../components/Form/Formik/FormikCheckbox';
import { FormikSubmitButton } from '../../../../components/Form/Formik/FormikSubmit';
import { formInitialValues, ORDER_STATUS } from '../consts';
import { useCallback } from 'react';
import * as Yup from 'yup';
import FormikInput from '../../../../components/Form/Formik/FormikInput';
import { useTranslation } from 'react-i18next';
import { resources, translations } from '../../../../translations/translationsConstants';
import DateField from '../../../../components/DateField';

export default function Filter({ orderList, onSubmit }) {
  const { t } = useTranslation([resources.orderList, resources.common]);

  const prepareFilesStatus = (values) => {
    const status = [];

    if (values.inProgress) {
      status.push(ORDER_STATUS['created']);
      status.push(ORDER_STATUS['inProgress']);
    }

    if (values.completed) {
      status.push(ORDER_STATUS['completed']);
      status.push(ORDER_STATUS['download']);
    }

    if (values.error) {
      status.push(ORDER_STATUS['rejected']);
      status.push(ORDER_STATUS['canceled']);
      status.push(ORDER_STATUS['error']);
    }

    return status;
  };

  const getNumberOfOrderByStatus = useCallback(() => {
    return orderList.reduce(
      (acc, value) => {
        if (
          value.orderStatus === ORDER_STATUS.rejected ||
          value.orderStatus === ORDER_STATUS.canceled ||
          value.orderStatus === ORDER_STATUS.error
        ) {
          acc.error += 1;
        } else if (
          value.orderStatus === ORDER_STATUS.created ||
          value.orderStatus === ORDER_STATUS.inProgress
        ) {
          acc.inProcess += 1;
        } else if (
          value.orderStatus === ORDER_STATUS.completed ||
          value.orderStatus === ORDER_STATUS.download
        ) {
          acc.signed += 1;
        }
        return acc;
      },
      { inProcess: 0, signed: 0, error: 0 }
    );
  }, [orderList]);

  return (
    <>
      <Formik
        initialValues={formInitialValues}
        onSubmit={(values, actions) => {
          const fileStatus = prepareFilesStatus(values);
          onSubmit(
            {
              signature: values.signature,
              fromDate: values.dateFrom
                ? DateTimeParser.toLocalJSONFormat(DateTimeParser.setSOD(values.dateFrom))
                : null,
              toDate: values.dateTo
                ? DateTimeParser.toLocalJSONFormat(DateTimeParser.setEOD(values.dateTo))
                : null,
              isShowMyFieldOnly: values.isShowingOnlyMyFiles,
              fileStatus
            },
            actions.setSubmitting
          );
        }}
      >
        {(props) => (
          <Form>
            <div className="w-full mt-5 mb-10 pb-5 border-b border-gray-200">
              <div className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-4 lg:grid-cols-6">
                <div className="col-span-1 sm:col-span-2">
                  <FormikInput
                    label={t(translations.orderList.filter.signature)}
                    name="signature"
                    formikProps={props}
                  />
                </div>

                <div className="col-span-1 sm:col-span-1">
                  <DateField
                    navbar={true}
                    label={t(translations.orderList.filter.dateFrom)}
                    name="dateFrom"
                    value={props.values.dateFrom}
                    maxDate={props.values.dateTo}
                    onChange={(v, e) => {
                      props.setFieldValue('dateFrom', v);
                    }}
                  />
                </div>

                <div className="col-span-1 sm:col-span-1">
                  <DateField
                    navbar={true}
                    label={t(translations.orderList.filter.dateTo)}
                    name="dateTo"
                    value={props.values.dateTo}
                    minDate={props.values.dateFrom}
                    onChange={(v, e) => {
                      props.setFieldValue('dateTo', v);
                    }}
                  />
                </div>
              </div>

              <div className="mt-6 grid gap-6 grid-cols-1 sm:grid-cols-none sm:grid-flow-col sm:auto-cols-max">
                <FormikCheckbox
                  label={`${t(translations.orderList.filter.inProcess)}`}
                  name="inProgress"
                  formikProps={props}
                  styles="focus:ring-gray-400 focus:border-gray-400 h-4 w-4 text-indigo-600 rounded"
                />

                <FormikCheckbox
                  label={`${t(translations.orderList.filter.signed)}`}
                  name="completed"
                  formikProps={props}
                  styles="focus:ring-gray-400 focus:border-gray-400 h-4 w-4 text-indigo-600 rounded"
                />

                <FormikCheckbox
                  label={`${t(translations.orderList.filter.canceled)}`}
                  name="error"
                  formikProps={props}
                  styles="focus:ring-gray-400 focus:border-gray-400 h-4 w-4 text-indigo-600 rounded"
                />

                <FormikCheckbox
                  label={t(translations.orderList.filter.myFilesOnly)}
                  name="isShowingOnlyMyFiles"
                  formikProps={props}
                  styles="focus:ring-gray-400 focus:border-gray-400 h-4 w-4 text-indigo-600 rounded"
                />
              </div>

              <div className="flex justify-end mt-6">
                <Button variant={Button.variants.secondary} onClick={props.resetForm}>
                  {t(translations.common.clear, { ns: resources.common })}
                </Button>

                <FormikSubmitButton
                  id="search"
                  text={t(translations.common.search, { ns: resources.common })}
                  className="ml-2 i"
                  formikProps={props}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export const PROVIDERS = {
  1: 'Signicat',
  2: 'Verified',
  3: 'Scrive'
};

export const signingSequenceType = {
  Initial: 0,
  DependsOnInitial: 1,
  NoOrder: 2
};

export const defaultLangugae = 'en';

export const initialFormValues = {
  signingOrderFiles: [],
  packageName: '',
  useSignatureOrder: false,
  signatories: [
    {
      emailAddress: '',
      firstName: '',
      lastName: '',
      signingSequence: signingSequenceType.NoOrder,
      signedData: '',
      signUrl: '',
      signStatus: 0
    }
  ],
  language: 'en',
  documentDescription: 'Hardcoded',
  signingNotificationTitle: '',
  signingNotificationMessage: '',
  emailNotificationSubject: '',
  emailNotificationMessage: '',
  distributeSignedDocumentToAllSignatories: true
};

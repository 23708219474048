import { Button } from '../../../../components';
import React from 'react';
import { CheckIcon } from '@heroicons/react/outline';
import { useHistory } from 'react-router-dom';
import { config } from '../../../../utils/config';
import { useTranslation } from 'react-i18next';
import { resources, translations } from '../../../../translations/translationsConstants';
import { useSessionStorage } from '../../../../hooks/useSessionStorage';

const Summary = ({ onReset }) => {
  const history = useHistory();
  const { isOnlySigningCreator } = useSessionStorage();
  const { t } = useTranslation([resources.orderCreate, resources.common]);

  return (
    <div data-cy="summaryStep" className="flex flex-col items-center py-10">
      <div className="flex items-center">
        <div className="p-4 bg-green-100 rounded-full">
          <CheckIcon className="w-6 h-6  text-green-800" aria-hidden="true" />
        </div>

        <div className="ml-4">
          <h1 className="mb-2 text-lg leading-6 font-medium text-gray-900">
            {t(translations.orderCreate.summary.title)}
          </h1>
          <p className="pb-2">{t(translations.orderCreate.summary.text)}</p>
        </div>
      </div>

      <div className="flex justify-end mt-6 pt-10">
        {isOnlySigningCreator() ? (
          <Button
            variant={Button.variants.secondary}
            className="ml-2"
            onClick={() => {
              window.location.assign(config.smartDocsUiBaseUrl);
            }}
          >
            Go to SmartDocs
          </Button>
        ) : (
          <>
            <Button variant={Button.variants.secondary} onClick={onReset}>
              {t(translations.orderCreate.summary.createNewOne)}
            </Button>

            <Button
              variant={Button.variants.secondary}
              className="ml-2"
              onClick={() => {
                history.push('/admin/digital-signing');
              }}
            >
              {t(translations.orderCreate.summary.goToList)}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default Summary;

import styles from './Table2.module.scss';
import { useClassNames } from '../../hooks/useClassNames';
import React, { memo } from 'react';

const Table2 = ({ columns, rows, expandRow, handleSortBy }) => {
  const { classNames } = useClassNames();

  const handleOnRowClick = (row) => {
    if (expandRow?.onClick) {
      expandRow.onClick(row);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="py-2 overflow-x-auto align-middle inline-block min-w-full">
        <div className="shadow-sm border border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr className={classNames(styles.headerRow)}>
                {expandRow?.expandColumnRenderer && (
                  <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                )}

                {columns.map((column) => (
                  <th
                    key={column.key}
                    className={classNames(
                      column.className ||
                        'py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    )}
                  >
                    <button aria-label={'Sort table by column "' + column.value + '"'} onClick={() => handleSortBy(column.key)}>{column.value}</button>
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className={classNames('p-4 bg-white divide-y divide-gray-200', styles.body)}>
              {rows.map((row, index) => (
                <React.Fragment key={index}>
                  <tr
                    className={classNames(
                      'relative max-md:grid max-md:pb-10',
                      styles.bodyRow,
                      index % 2 === 0 ? undefined : 'bg-gray-50'
                    )}
                  >
                    <>
                      {expandRow?.expandColumnRenderer && (
                        <td
                          className={classNames(
                            'px-6 py-4 text-sm text-gray-500 cursor-pointer max-md:col-span-2',
                            styles.expandColumn
                          )}
                          onClick={() => handleOnRowClick(row)}
                        >
                          <div>{expandRow.expandColumnRenderer(row)}</div>
                        </td>
                      )}

                      {<BodyRow row={row} columns={columns} />}
                    </>
                  </tr>

                  {expandRow.condition(row) && (
                    <tr
                      className={classNames(
                        'max-md:grid',
                        styles.bodyRow,
                        index % 2 === 0 ? undefined : 'bg-gray-50'
                      )}
                    >
                      <td
                        colSpan={10}
                        className={classNames('px-6 py-4 text-sm text-gray-500 bg-white')}
                      >
                        {expandRow.renderer(row)}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const BodyRow = ({ columns, row }) => {
  const { classNames } = useClassNames();

  const renderCell = (row, column) => {
    let result = null;
    if (column.formatter) {
      result = (
        <td
          key={column.key}
          className={classNames(
            column.formatterClassName || 'px-6 py-4 text-sm text-gray-500',
            column.nowrap && 'whitespace-nowrap'
          )}
        >
          <>{column.formatter(row)}</>
        </td>
      );
    } else if (row.hasOwnProperty(column.key)) {
      result = (
        <td
          key={column.key}
          className={classNames(
            'px-6 py-4 text-sm text-gray-500',
            column.nowrap && 'whitespace-nowrap'
          )}
        >
          <div className="mb-2 text-sm font-medium md:hidden">{column.value}</div>
          <div className="text-gray-500">{row[column.key]}</div>
        </td>
      );
    }

    return result;
  };

  return columns.map((column) => renderCell(row, column));
};

export default Table2;

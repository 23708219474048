export const adminProductId = "611b8ac465064876fb35e1cf";
export const designFeatureCode = "DSF";
export const dataFeatureCode = "DDF";
export const templateFeatureCode = "DTF";

export const designContexts = {
  element: 'element',
  row: 'row',
  column: 'column',
  container: 'container',
}

export const editStates = {
  draft: 1,
}

export const dataLoadStates = {
  imported: 1,
  dialogsGenerated: 5,
  active: 10,
  notfied: 11
}
export const dataLoadNotificationStates = {
  notSet: 1,
  draft: 5,
  issued: 10
}

export const dataLoadTypes = {
  localFile: 1,
  ftp: 2,
  api: 3,
  crm: 4
}

export const elementTypes = {
  textLine: 0,
  textArea: 1,
  inputTextLine: 2,
  headerLine: 3,
  bitmatp: 4,
  datePicker: 5,
  button: 6,
  radioButton: 7,
  divider: 8,
  radioList: 9,
  table: 10,
  radioListAsGroup: 11,
  fileUpload: 12,
  radioButtons: 13,
  disclaimer: 14,
  dropDown: 15,
  flowSelector: 16,
  checkbox: 17,
  cancelButton: 18,
  signature: 19,
  timePicker: 20,
  attachment: 21,
  video: 22,
  hyperLink: 23,
  richText: 24,
  dynamicTable: 25,
  dynamicList: 26
}

export const dialogDefinitionStatuses = {
  active: 0,
  inactive: 1,
}

export const dialogObjectStatuses = {
  active: 0,
  inactive: 1,
}

export const webhookStatuses = {
  active: 0,
  inactive: 1,
}

export const webhookTypes = {
  manual: 0,
}

export const webhookSources = {
  smartForms: 0,
  zapier: 1,
}

export const securityType = {
  none: 1,
  email: 2,
  phone: 3,
}
export const securityTypeOptions = [
  {
    value: securityType.none,
    name: 'None',
  },
  {
    value: securityType.email,
    name: 'Email',
  },
  {
    value: securityType.phone,
    name: 'Phone',
  },
]

export const processTypes = {
  normal: "Normal",
  multiStep: "Multi-Step",
}

export const dialogValuesStepStatus = {
  notStarted: 1,
  inProgress: 2,
  completed: 3,
}
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, FormStepper } from '../../../components';
import PersonToSign from './components/PersonToSign';
import Documents from './components/Documents';
import {
  createNewOrder,
  getCreateOrderInitialDataApi,
  getPortalUISettingsApi
} from '../../../api/signingOrder';
import Summary from './components/Summary';
import { defaultLangugae, initialFormValues, PROVIDERS, signingSequenceType } from './const';
import { useToastAction } from '../../../hooks/useToastAction';
import { Form, Formik } from 'formik';
import { FormikSubmitButton } from '../../../components/Form/Formik/FormikSubmit';
import { useTranslation } from 'react-i18next';
import { resources, translations } from '../../../translations/translationsConstants';
import * as Yup from 'yup';
import { OidcRoutesContext } from '../../../contexts';
import Alert, { AlertType } from '../../../components/Alert/Alert';
import { SubmitButton } from '../../../components/Form/SubmitButton';

const FILE_SIZE = 10240 * 1024;
const SUPPORTED_FORMATS = ['application/pdf'];

const responseTypes = {
  success: 0,
  error: 1,
  warning: 2
};

const DigitalSigningCreator = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [portalUISettings, setPortalUISettings] = useState();
  const [languages, setLanguages] = useState();
  const [archiveSettings, setArchiveSettings] = useState(0);
  const [formValues, setFormValues] = useState(initialFormValues);
  const [createNewOrderSettings, setCreateNewOrderSettings] = useState({
    disabled: false,
    disableOnInit: false,
    message: ''
  });

  const { userProfile } = useContext(OidcRoutesContext);
  const { t } = useTranslation([resources.orderCreate, resources.common]);
  const createOrderAction = useToastAction();
  const getCreateOrderInitialDataAction = useToastAction();
  const getProvidersSettingsAction = useToastAction();
  const initLoadAction = useToastAction();

  const createOrder = (request) =>
    createOrderAction.execute(async () => {
      const createNewOrderResult = await createNewOrder(request);
      let message = t(translations.orderCreate.summary[createNewOrderResult.message]);

      if (createNewOrderResult.type === responseTypes.warning) {
        if (createNewOrderResult.message === 'licence_order_will_exceed_usage') {
          message = t(translations.orderCreate.summary.licence_order_will_exceed_usage, {
            used: createNewOrderResult.data.currentUsage,
            available: createNewOrderResult.data.maxUsage
          });
        }

        setCreateNewOrderSettings({
          disabled: false,
          disableOnInit: false,
          message
        });
      } else if (createNewOrderResult.type === responseTypes.error) {
        setCreateNewOrderSettings({
          disabled: false,
          disableOnInit: false,
          message
        });
      } else {
        setActiveStep(3);
      }
    }, t(translations.orderCreate.failedToCreateNewOrder));

  const getProvidersSettings = async () => {
    let result = {};

    await getProvidersSettingsAction.execute(async () => {
      result = getPortalUISettingsApi();
    }, 'Failed to get app settings.');

    return result;
  };

  const getFileContent = useCallback((file) => {
    return new Promise((resolve) => {
      let content = '';
      let reader = new FileReader();

      reader.onload = () => {
        content = reader.result.replace('data:', '').replace(/^.+,/, '');
        resolve(content);
      };

      reader.readAsDataURL(file);
    });
  }, []);

  const loopFiles = useCallback(async (values) => {
    const signingOrderFiles = [];

    for (let i = 0; i < values.signingOrderFiles.length; i++) {
      const fileAsBase64 = await getFileContent(values.signingOrderFiles[i]);
      signingOrderFiles.push({
        name: values.signingOrderFiles[i].name,
        fileContent: fileAsBase64
      });
    }

    return signingOrderFiles;
  }, []);

  const handleOnSubmit = useCallback(async (values) => {
    const signingOrderFiles = await loopFiles(values);
    let filledSignatories = [];
    await values?.signatories?.forEach(element => {
      if(element.emailAddress?.length > 0) filledSignatories.push(element);
    });
    values.signatories = filledSignatories;
    createOrder({
      ...values,
      signingOrderFiles
    });
  }, []);

  const handleOnReset = useCallback(async () => {
    initLoad();
    setActiveStep(1);
  }, []);

  const getInitialData = useCallback(async (language = null) => {
    let result = {};

    await getCreateOrderInitialDataAction.execute(() => {
      result = getCreateOrderInitialDataApi(language || userProfile.languageCode);
    }, t(translations.common.status.signatory.warning, { ns: resources.common }));

    return result;
  }, []);

  const handleOnLanguageChange = async (language, formValues) => {
    const initialDataResult = await getInitialData(language);

    setFormValues({
      ...formValues,
      language: language,
      signingNotificationTitle: initialDataResult.providerSigningSubject,
      signingNotificationMessage: initialDataResult.providerSigningMessage,
      emailNotificationSubject: initialDataResult.emailSigningSubject,
      emailNotificationMessage: initialDataResult.emailSigningMessage,
      availableFolders: initialDataResult.availableFolders
    });
  };

  const initLoad = () => {
    initLoadAction.execute(async () => {
      const [providersSettingsResult, initialDataResult] = await Promise.all([
        getProvidersSettings(),
        getInitialData()
      ]);

      const selectedLanguage = () => {
        const foundLanguage = initialDataResult.languages.find(
          (l) => l.languageCode === userProfile.languageCode
        );

        return foundLanguage ? foundLanguage.languageCode : defaultLangugae;
      };

      setPortalUISettings({
        ...providersSettingsResult,
        providerType: initialDataResult.providerType
      });

      setLanguages(initialDataResult.languages);

      setArchiveSettings({
        archiveType: initialDataResult.archiveType,
        availableFolders: initialDataResult.availableFolders
      });

      setCreateNewOrderSettings({
        disabled: !initialDataResult.isCreateNewOrderAvailable,
        disableOnInit: !initialDataResult.isCreateNewOrderAvailable,
        message: t(translations.orderCreate.summary[initialDataResult.orderAvailabilityMessage])
      });

      setFormValues((values) => ({
        ...values,
        language: selectedLanguage(),
        signingNotificationTitle: initialDataResult.providerSigningSubject,
        signingNotificationMessage: initialDataResult.providerSigningMessage,
        emailNotificationSubject: initialDataResult.emailSigningSubject,
        emailNotificationMessage: initialDataResult.emailSigningMessage,
        archiveFolderId: initialDataResult.availableFolders.length
          ? initialDataResult.availableFolders[0].id
          : null
      }));
    }, t(translations.common.failedToLoadData, { ns: resources.common }));
  };

  const formSteps = useMemo(
    () => [
      {
        order: 1,
        name: t(translations.orderCreate.steps.document),
        component: <Documents key="documents" />
      },
      {
        order: 2,
        name: t(translations.orderCreate.steps.personToSign),
        component: <PersonToSign key="personToSign" />
      },
      {
        order: 3,
        name: t(translations.orderCreate.steps.summary),
        component: <Summary key="summary" onReset={handleOnReset} />
      }
    ],
    []
  );
  const basicValidation = [
    Yup.object().shape({
      packageName: Yup.string()
        .max(100, t(translations.orderCreate.documentStep.validation.maxLengthCharacters))
        .required(t(translations.orderCreate.documentStep.validation.packageName)),
      signingOrderFiles: Yup.mixed()
        .test(
          'minLength',
          t(translations.orderCreate.documentStep.validation.atLeastDocument),
          (files) => files.length > 0
        )
        .test('fileSize', t(translations.orderCreate.documentStep.validation.fileSize), (files) => {
          const size = files.reduce((prev, current) => {
            return prev + current.size;
          }, 0);
          return size <= FILE_SIZE;
        })
        .test(
          'fileFormat',
          t(translations.orderCreate.documentStep.validation.unsupportedFileType),
          (files) => {
            const unsupportedTypes = files.filter((f) => !SUPPORTED_FORMATS.includes(f.type));
            return unsupportedTypes.length === 0;
          }
        )
    })
  ];

  const validationWithoutNames = [
    Yup.object().shape({
      signatories: Yup.array()
        .compact((v) => {
          return v.emailAddress === undefined;
        })
        .of(
          Yup.object().shape({
            emailAddress: Yup.string()
              .email(t(translations.orderCreate.personToSignStep.validation.invalidEmail))
              .required(t(translations.orderCreate.personToSignStep.validation.enterEmail))
          })
        )
        .test(
          'dependsOnInitial',
          t(translations.orderCreate.personToSignStep.validation.noInitialOrderType),
          (value) => {
            const isDependOnInitial = value.find(
              (v) => v.signingSequence === signingSequenceType.DependsOnInitial
            );

            if (isDependOnInitial) {
              return value.find((v) => v.signingSequence === signingSequenceType.Initial);
            }

            return true;
          }
        )
        .test(
          'minLength',
          t(translations.orderCreate.personToSignStep.validation.atLeastSignatory),
          (files) => files.length > 0
        ),
      signingNotificationTitle: Yup.string().required(
        t(translations.common.validation.required, { ns: resources.common })
      ),
      signingNotificationMessage: Yup.string().required(
        t(translations.common.validation.required, { ns: resources.common })
      ),
      emailNotificationSubject: Yup.string().required(
        t(translations.common.validation.required, { ns: resources.common })
      ),
      emailNotificationMessage: Yup.string().required(
        t(translations.common.validation.required, { ns: resources.common })
      )
    })
  ]
  
  const validationWithNames = [
    Yup.object().shape({
      signatories: Yup.array()
        .compact((v) => {
          return v.emailAddress === undefined;
        })
        .of(
          Yup.object().shape({
            emailAddress: Yup.string()
              .email(t(translations.orderCreate.personToSignStep.validation.invalidEmail))
              .required(t(translations.orderCreate.personToSignStep.validation.enterEmail)),
            firstName: Yup.string().required(t(translations.common.validation.required, { ns: resources.common })),
            lastName: Yup.string().required(t(translations.common.validation.required, { ns: resources.common }))
          })
        )
        .test(
          'dependsOnInitial',
          t(translations.orderCreate.personToSignStep.validation.noInitialOrderType),
          (value) => {
            const isDependOnInitial = value.find(
              (v) => v.signingSequence === signingSequenceType.DependsOnInitial
            );

            if (isDependOnInitial) {
              return value.find((v) => v.signingSequence === signingSequenceType.Initial);
            }

            return true;
          }
        )
        .test(
          'minLength',
          t(translations.orderCreate.personToSignStep.validation.atLeastSignatory),
          (files) => files.length > 0
        ),
      signingNotificationTitle: Yup.string().required(
        t(translations.common.validation.required, { ns: resources.common })
      ),
      signingNotificationMessage: Yup.string().required(
        t(translations.common.validation.required, { ns: resources.common })
      ),
      emailNotificationSubject: Yup.string().required(
        t(translations.common.validation.required, { ns: resources.common })
      ),
      emailNotificationMessage: Yup.string().required(
        t(translations.common.validation.required, { ns: resources.common })
      )
    })
  ];

  const stepsValidation =                           
  portalUISettings?.providersSettings[
    PROVIDERS[portalUISettings?.providerType]
  ]?.displayFirstName 
  && portalUISettings?.providersSettings[
    PROVIDERS[portalUISettings?.providerType]
  ]?.displayLastName 
    ? basicValidation.concat(validationWithNames)
    : basicValidation.concat(validationWithoutNames);

  const handleOnPreviousClick = () => {
    setActiveStep((step) => step - 1);
  };

  useEffect(() => {
    if (portalUISettings) {
      const settings = portalUISettings[PROVIDERS[portalUISettings.providerType]];
      if (settings && !settings.enableSendingEmailAfterSigningIsCompleted) {
        setFormValues((values) => ({
          ...values,
          distributeSignedDocumentToAllSignatories: false
        }));
      }
    }
  }, [portalUISettings]);

  useEffect(() => {
    initLoad();
  }, []);

  return (
    <div className="p-4">
      <div className="flex flex-col pb-5 mb-10 border-b border-gray-200">
        <h1 className="text-3xl font-bold">{t(translations.orderCreate.title)}</h1>
        <p className="mt-2 max-w-4xl text-sm text-gray-500">{t(translations.orderCreate.text)}</p>
      </div>

      <div>
        {!initLoadAction.isExecuting && portalUISettings && (
          <Formik
            initialValues={formValues}
            enableReinitialize={true}
            validationSchema={stepsValidation[activeStep - 1]}
            onSubmit={async (values, actions) => {
              if (activeStep + 1 === formSteps.length) {
                await handleOnSubmit(values);
                actions.setSubmitting(false);
              } else {
                setActiveStep((step) => step + 1);
                actions.setErrors({});
                actions.setTouched({});
                actions.setSubmitting(false);
              }
            }}
          >
            {(props) => (
              <Form>
                <div className="p-0 shadow-sm rounded-lg border border-gray-200 bg-white">
                  <FormStepper steps={formSteps} currentStep={activeStep} />
                </div>

                <div className="p-5 shadow-sm rounded-lg border border-gray-200 bg-white mt-4 p-8 space-y-8 bg-white shadow rounded-lg">
                  <div>
                    {createNewOrderSettings.message.length > 0 && (
                      <Alert
                        msg={createNewOrderSettings.message}
                        type={AlertType.Warning}
                        className="mb-4"
                      />
                    )}
                    {activeStep === 1 ? (
                      <Documents formikProps={props} />
                    ) : activeStep === 2 ? (
                      <PersonToSign
                        formikProps={props}
                        languages={languages}
                        archiveSettings={archiveSettings}
                        appSettings={
                          portalUISettings.providersSettings[
                            PROVIDERS[portalUISettings.providerType]
                          ]
                        }
                        onLanguageChange={handleOnLanguageChange}
                      />
                    ) : (
                      <Summary onReset={handleOnReset} />
                    )}
                  </div>

                  <div
                    data-cy="createOrderNavigation"
                    className="flex justify-end pt-5 border-t border-gray-200"
                  >
                    {activeStep < formSteps.length && (
                      <>
                        <Button
                          variant={Button.variants.secondary}
                          disabled={activeStep === 1}
                          onClick={handleOnPreviousClick}
                        >
                          {t(translations.common.previous, { ns: resources.common })}
                        </Button>

                        {activeStep < formSteps.length - 1 ? (
                          <SubmitButton
                            id="submit"
                            text={t(translations.common.next, { ns: resources.common })}
                            className="ml-2 i"
                            disabled={
                              createNewOrderSettings.disableOnInit || createOrderAction.isExecuting
                            }
                          />
                        ) : (
                          <FormikSubmitButton
                            id="submit"
                            text={t(translations.common.send, { ns: resources.common })}
                            className="ml-2 i"
                            formikProps={props}
                            disabled={
                              createNewOrderSettings.disabled || createOrderAction.isExecuting
                            }
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default DigitalSigningCreator;

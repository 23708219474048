import {
  CheckIcon,
  EyeIcon,
  MinusIcon,
  PencilIcon,
  PlusIcon,
  XIcon
} from '@heroicons/react/outline';
import './styles.scss';
import { Link } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { useToastAction } from '../../../hooks/useToastAction';
import { cancelOrder, getActivities, logLoginApi } from '../../../api/signingOrder';
import { stringToLocaleDateTimeString } from '../../../utils/date';
import { UsersIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import { resources, translations } from '../../../translations/translationsConstants';
import { Tile } from '../../../components/Layout';
import { Card } from '@metaforcelabs/metaforce-core';
import { useSessionStorage } from '../../../hooks/useSessionStorage';
import { ORDER_STATUS } from '../DigitalSigning/consts';
import { MenuContextList } from '../../../components';

const Dashboard = () => {
  const [activities, setActivities] = useState([]);

  const { isLoggedIn, setIsLoggedIn } = useSessionStorage();
  const { t } = useTranslation([resources.home, resources.common]);
  const activitiesAction = useToastAction();
  const logLoginAction = useToastAction();
  const cancelOrderAction = useToastAction();

  const fetchActivities = useCallback(() => {
    activitiesAction.execute(async () => {
      const activitiesResult = await getActivities();
      setActivities(activitiesResult);
    }, t(translations.home.activities.failedToGetActivities));
  }, []);

  const getOrderStatus = (orderStatus) => {
    switch (orderStatus) {
      case 1:
        return (
          <>
            <span className="mr-2 p-0.5 bg-blue-500 rounded-full">
              <PlusIcon className="w-3 h-3 text-white font-black" />
            </span>
            {t(translations.common.status.order.created, { ns: resources.common })}
          </>
        ); //Created
      case 5:
        return (
          <>
            <span className="mr-2 p-0.5 bg-yellow-500 rounded-full">
              <MinusIcon className="w-3 h-3 text-white font-black" />
            </span>
            {t(translations.common.status.order.inProgress, { ns: resources.common })}
          </>
        ); //In Progress
      case 10:
        return (
          <>
            <span className="mr-2 p-0.5 bg-green-500 rounded-full">
              <CheckIcon className="w-3 h-3 text-white font-black" />
            </span>
            {t(translations.common.status.order.completed, { ns: resources.common })}
          </>
        ); //Completed
      case 20:
        return (
          <>
            <span className="mr-2 p-0.5 bg-green-500 rounded-full">
              <CheckIcon className="w-3 h-3 text-white font-black" />
            </span>
            {t(translations.common.status.order.completed, { ns: resources.common })}
          </>
        ); //Downloaded From Signing Provider
      case 50:
        return (
          <>
            <span className="mr-2 p-0.5 bg-red-500 rounded-full">
              <XIcon className="w-3 h-3 text-white font-black" />
            </span>
            {t(translations.common.status.order.canceled, { ns: resources.common })}
          </>
        );
      case 70:
        return (
          <>
            <span className="mr-2 p-0.5 bg-red-500 rounded-full">
              <XIcon className="w-3 h-3 text-white font-black" />
            </span>
            {t(translations.common.status.order.canceled, { ns: resources.common })}
          </>
        );
      case 99:
        return (
          <>
            <span className="mr-2 p-0.5 bg-red-500 rounded-full">
              <XIcon className="w-3 h-3 text-white font-black" />
            </span>
            {t(translations.common.status.order.error, { ns: resources.common })}
          </>
        );
      default:
        return (
          <>
            <span className="mr-2 p-0.5 bg-yellow-500 rounded-full">
              <MinusIcon className="w-3 h-3 text-white font-black" />
            </span>
            {t(translations.common.status.order.notCompleted, { ns: resources.common })}
          </>
        );
    }
  };

  const handleOrderCancel = (id) => {
    cancelOrderAction.execute(async () => {
      const cancelOrderResult = await cancelOrder(id);
      if (cancelOrderResult) {
        fetchActivities();
      }
    },
    t(translations.common.failedToExecuteCancelAction, { ns: resources.common }));
  };

  const renderRowActionsBtn = (row) => {
    const actions = [
      {
        id: 1,
        name: t(translations.common.cancel, {
          ns: resources.common
        }),
        condition: ![ORDER_STATUS.canceled, ORDER_STATUS.download, ORDER_STATUS.completed].includes(
          row.status
        ),
        onClick: () => handleOrderCancel(row.id)
      },
    ].filter((a) => a.condition);
    return actions.length > 0 && <MenuContextList container="body" actions={actions} />;
  };

  useEffect(() => {
    fetchActivities();
    if (!isLoggedIn()) {
      logLoginAction.execute(async () => {
        await logLoginApi();
        setIsLoggedIn();
      }, '');
    }
  }, []);

  return (
    <div className="w-full h-full">
      <h1 className="text-3xl font-bold">{t(translations.home.title)}</h1>

      <h3 className="mt-4 text-gray-600">{t(translations.home.text)}</h3>

      <div
        data-cy="tilesParent"
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 lg:gap-10 mt-10"
      >
        <Card shouldRemovePadding>
          <Link to="/admin/digital-signing/creator">
            <Tile
              title={t(translations.home.tiles.create.title)}
              text={t(translations.home.tiles.create.text)}
              icon={PencilIcon}
            />
          </Link>
        </Card>

        <Card shouldRemovePadding>
          <Link to="/admin/digital-signing">
            <Tile
              title={t(translations.home.tiles.list.title)}
              text={t(translations.home.tiles.list.text)}
              icon={EyeIcon}
              iconBgColor="bg-yellow-500"
            />
          </Link>
        </Card>
      </div>

      {activities.length > 0 && (
        <div className="mt-10 overflow-hidden sm:rounded-md grid grid-cols-1">
          <div className="mb-6">
            <h2 className="col-start-1 text-lg font-medium text-gray-900">
              {t(translations.home.activities.title)}
            </h2>

            <p className="col-start-1 mt-1 text-sm text-gray-500">
              {t(translations.home.activities.text)}
            </p>
          </div>

          <div className="shadow-sm rounded-lg border border-gray-200 bg-white lg:w-2/3 xl:w-1/2 min-h-2 ">
            <ul data-cy="listOfActivities" className="divide-y divide-gray-200">
              {activities.map((a, index) => (
                <li key={index}>
                  <div className="block">
                    <div className="flex items-center p-4">
                      <div className="min-w-0 flex-1 flex items-center">
                        <div className="min-w-0 flex-1 md:grid md:grid-cols-8 md:gap-4">
                          <div className="md:col-span-4">
                            <p
                              data-cy="activityItemNameInfo"
                              className="text-sm font-medium text-gray-500 truncate"
                            >
                              {a.packageName} ({a.numOfDocuments}{' '}
                              {a.numOfDocuments > 1
                                ? t(translations.home.activities.documents)
                                : t(translations.home.activities.document)}
                              )
                            </p>
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                              <UsersIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                              {t(translations.home.activities.hasSigned, {
                                signed: a.signedSignatories,
                                total: a.totalSignatories
                              })}
                            </p>
                          </div>
                          <div className="mt-4 md:mt-0 md:col-span-3">
                            <div>
                              <p className="text-sm text-gray-500">
                                {t(translations.home.activities.updatedOn, {
                                  date: stringToLocaleDateTimeString(a.updatedDate)
                                })}
                              </p>
                              <p
                                data-cy="activityItemStatusInfo"
                                className="mt-2 flex items-center text-sm text-gray-500 "
                              >
                                {getOrderStatus(a.status)}
                              </p>
                            </div>
                          </div>
                          <div className="mt-8 mb-5 md:ml-auto md:mt-4">
                            {renderRowActionsBtn(a)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;

import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';
import { config } from '../../utils/config';
import { OidcRoutesContext } from '../../contexts';
import { MenuBar, useWhiteLabel } from '@metaforcelabs/metaforce-core';
import { useSessionStorage } from '../../hooks/useSessionStorage';

const AdminLayout = ({ nav, children }) => {
  const oidcRoutesContext = useContext(OidcRoutesContext);
  const { isOnlySigningCreator } = useSessionStorage();
  const { bgColor } = useWhiteLabel();

  const navigationMenu = [
    {
      linkElement: !isOnlySigningCreator() ? <Link to="/admin/home">Home</Link> : <a href={config.smartDocsUiBaseUrl} rel="noreferrer">Home</a>,
      name: 'Home',
      current: nav === 'home',
      featureEnabled: !isOnlySigningCreator()
    }
  ];

  return (
    <div className="relative flex flex-col bg-white min-h-screen">
      <MenuBar
        navigation={navigationMenu}
        customerName={oidcRoutesContext?.customerInfo?.name}
        customerOrgNumber={oidcRoutesContext?.customerInfo?.orgNumber}
        homeButtonLink={config?.centerpointUiBaseUrl}
        userProfileImage={oidcRoutesContext?.userProfile?.userProfileImage}
        userName={oidcRoutesContext.userProfile.fullName}
        maxWidthOverride='max-w-screen-2xl'
        helpLink={config.docsUrl}
      />

      <main
        style={{
          backgroundColor: bgColor
        }}
        className="flex-1 main-container bg-brand-background focus:outline-none h-full"
      >
        <div className="max-w-screen-2xl mx-auto inset-0 py-6 px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col pb-10 h-full">
            <div className="max-w-screen-2xl">{children}</div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AdminLayout;

import classNames from 'classnames';
import { Field } from 'formik';

export default function FormikField({ name, children, className }) {
  return (
    <div className={classNames(className)}>
      <Field name={name}>{(fieldProps) => children(fieldProps)}</Field>
    </div>
  );
}

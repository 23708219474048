import { Field, ErrorMessage } from 'formik';

export const FormikCheckbox = ({
  name,
  label,
  secondaryLabel,
  formikProps,
  onBlur,
  onClick,
  type = null,
  placeholder = null,
  required = false,
  styles
}) => {
  const hasError = formikProps?.errors && formikProps.errors[name];
  const errorClasses = 'ring-red-500 border-red-500 focus:ring-red-500 focus:border-red-500';
  return (
    <div className="mt-1">
      <Field
        type={type || 'checkbox'}
        name={name}
        className={`shadow-sm w-5 h-5 mr-4 sm:text-sm border-gray-300 focus:ring-gray-400 focus:border-gray-400 ${
          styles ? styles : ''
        } ${hasError ? errorClasses : ''}`}
        placeholder={placeholder}
        onBlur={onBlur}
        onClick={onClick}
      />
      <label htmlFor={name} className="text-sm font-medium text-gray-900">
        {label}
        {required === true && <span className="text-red-400"> *</span>}
      </label>

      {secondaryLabel && (
        <label htmlFor={name} className="mt-2 flex flex-col text-xs	font-medium text-gray-700">
          {secondaryLabel}
        </label>
      )}

      <ErrorMessage name={name} component="div" className="text-red-500" />
    </div>
  );
};
